<template>
  <div class="mainwidth">
    <div class="digitization-content">
      <h2>问卷调查</h2>
      <a-form
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        layout="vertical"
        ref="formRef"
      >
        <a-form-item label="1.企业名称">
          <a-input
            v-model:value="enterpriseName"
            placeholder="请输入企业名称"
          />
        </a-form-item>
        <a-form-item label="2.社会机构代码">
          <a-input
            v-model:value="creditCode"
            placeholder="请输入社会机构代码"
          />
        </a-form-item>
        <a-form-item label="3.企业负责人">
          <a-input v-model:value="director" placeholder="请输入企业负责人" />
        </a-form-item>
        <a-form-item label="4.联系电话">
          <a-input v-model:value="directorPhone" placeholder="请输入联系电话" />
        </a-form-item>
        <a-form-item label="5.企业类型">
          <a-select v-model:value="enterpriseType" placeholder="请选择企业类型">
            <a-select-option
              :value="item.value"
              v-for="(item, index) in enterpriseTypeList"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="6.行业分类">
          <a-select v-model:value="industryType" placeholder="请选择行业分类">
            <a-select-option
              :value="item.value"
              v-for="(item, index) in industryTypeList"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="7.申请额度">
          <a-input v-model:value="loanMoney" placeholder="请输入申请额度" />
        </a-form-item>
        <a-form-item label="8.销售规模">
          <a-select v-model:value="salesVolume" placeholder="请选择销售规模">
            <a-select-option
              :value="item.value"
              v-for="(item, index) in salesVolumeList"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="9.社保人员数量">
          <a-select v-model:value="staffCount" placeholder="请选择社保人员数量">
            <a-select-option
              :value="item.value"
              v-for="(item, index) in staffCountList"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="10.生产设备数量">
          <a-select
            v-model:value="equipmentCount"
            placeholder="请选择生产设备数量"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in equipmentCountList"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="11.生产设备金额">
          <a-select
            v-model:value="equipmentCost"
            placeholder="请选择生产设备金额"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in equipmentCostList"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="12.企业荣誉">
          <a-checkbox-group v-model:value="honors">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in honorsList"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="13.设备上云状态">
          <a-radio-group v-model:value="cloudStatus" name="radioGroup">
            <a-radio value="1">已完成</a-radio>
            <a-radio value="2">未完成</a-radio>
            <a-radio value="3">不知道</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </div>
    <div style="margin-top: 20px;margin-left: 50%">
      <a-button type="primary" @click="submit" style="width: 160px;height: 40px"
        >提交</a-button
      >
    </div>
    <!-- 结果查询弹窗 -->
    <a-modal
      v-model:visible="resultShow"
      title="结果查询"
      width="35%"
      :footer="null"
      :maskClosable="false"
    >
      <div class="success-title">
        恭喜您已完成资料填写,请耐心等待,工作人员会在24小时内联系您。
      </div>
      <div class="success-text">
        <p style="font-weight: bold;">
          如需进一步了解请拨打业务专线进行咨询:
        </p>
        <p>
          <span style="font-size: 16px;font-weight: bold;">数字化转型服务:</span
          ><span style="margin-left: 10px">卢思冰</span
          ><span style="color: #1089ff;margin-left: 5px;">15765999441</span>
        </p>
        <p>
          <span style="font-size: 16px;font-weight: bold;">融资服务:</span>
          <span style="margin-left: 10px">李志伟</span
          ><span style="color: #1089ff;margin-left: 5px;">15057771989</span>
        </p>
        <p>
          <span style="font-size: 16px;font-weight: bold;">政策申请: </span
          ><span style="margin-left: 10px">侯惠宁</span
          ><span style="color: #1089ff;margin-left: 5px;">18745349632</span>
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getDropDownList, applicationAdd } from "../api/survey/index";
export default {
  name: "Survey",
  data() {
    return {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
      resultShow: false,
      enterpriseName: "",
      creditCode: "",
      director: "",
      directorPhone: "",
      enterpriseType: "",
      industryType: "",
      loanMoney: "",
      salesVolume: "",
      staffCount: "",
      equipmentCount: "",
      equipmentCost: "",
      honors: [],
      cloudStatus: "1",
      timer: null,

      enterpriseTypeList: [],
      industryTypeList: [],
      salesVolumeList: [],
      staffCountList: [],
      equipmentCountList: [],
      equipmentCostList: [],
      honorsList: [],
      type: "",
    };
  },
  methods: {
    // 验证社会机构代码
    validateSocialCreditCode(code) {
      const regex = /^[0-9A-Z]{8}[0-9A-HJ-NPQRTUWXY]{9}[0-9A-HJ-NPQRTUWXYX]$/;
      return regex.test(code);
    },
    // 验证手机号
    validatePhoneNumber(phoneNumber) {
      const phoneRegex = /^1[3-9]\d{9}$/;
      return phoneRegex.test(phoneNumber);
    },
    // 验证输入数字
    validateNumber(number) {
      const numberRegex = /^\d+(\.\d+)?$/;
      return numberRegex.test(number);
    },
    getLabels(dictLabel) {
      getDropDownList({ dictType: dictLabel }).then((res) => {
        const result = res;
        console.log(result);
        
        if (result.code == 200) {
          if (dictLabel == "jr_industry_type") {
            this.industryTypeList = result.data.data.map((i) => {
              return {
                value: i.dictValue,
                label: i.dictLabel,
              };
            });
          } else if (dictLabel == "jr_enter_type") {
            this.enterpriseTypeList = result.data.data.map((i) => {
              return {
                value: i.dictValue,
                label: i.dictLabel,
              };
            });
          } else if (dictLabel == "sales_volume") {
            this.salesVolumeList = result.data.data.map((i) => {
              return {
                value: i.dictValue,
                label: i.dictLabel,
              };
            });
          } else if (dictLabel == "staff_count") {
            this.staffCountList = result.data.data.map((i) => {
              return {
                value: i.dictValue,
                label: i.dictLabel,
              };
            });
          } else if (dictLabel == "equipment_count") {
            this.equipmentCountList = result.data.data.map((i) => {
              return {
                value: i.dictValue,
                label: i.dictLabel,
              };
            });
          } else if (dictLabel == "equipment_cost") {
            this.equipmentCostList = result.data.data.map((i) => {
              return {
                value: i.dictValue,
                label: i.dictLabel,
              };
            });
          } else if (dictLabel == "honors") {
            this.honorsList = result.data.data.map((i) => {
              return {
                value: i.dictValue,
                label: i.dictLabel,
              };
            });
          }
        }
      });
    },
    submit() {
      if (this.enterpriseName == "undefined" || this.enterpriseName == "") {
        this.$message.info("请输入企业名称");
        return false;
      } else if (this.creditCode == "undefined" || this.creditCode == "") {
        this.$message.info("请输入社会机构代码");
        return false;
      } else if (!this.validateSocialCreditCode(this.creditCode)) {
        this.$message.info("请输入正确的社会机构代码");
        return false;
      } else if (this.director == "undefined" || this.director == "") {
        this.$message.info("请输入企业负责人");
        return false;
      } else if (
        this.directorPhone == "undefined" ||
        this.directorPhone == ""
      ) {
        this.$message.info("请输入联系电话");
        return false;
      } else if (!this.validatePhoneNumber(this.directorPhone)) {
        this.$message.info("请输入正确的联系电话");
        return false;
      } else if (
        this.enterpriseType == "undefined" ||
        this.enterpriseType == ""
      ) {
        this.$message.info("请选择企业类型");
        return false;
      } else if (this.industryType == "undefined" || this.industryType == "") {
        this.$message.info("请选择行业分类");
        return false;
      } else if (this.loanMoney == "undefined" || this.loanMoney == "") {
        this.$message.info("请输入申请额度");
        return false;
      } else if (!this.validateNumber(this.loanMoney)) {
        this.$message.info("请输入正确的金额");
        return false;
      } else if (this.salesVolume == "undefined" || this.salesVolume == "") {
        this.$message.info("请选择销售规模");
        return false;
      } else if (this.staffCount == "undefined" || this.staffCount == "") {
        this.$message.info("请选择社保人员数量");
        return false;
      } else if (
        this.equipmentCount == "undefined" ||
        this.equipmentCount == ""
      ) {
        this.$message.info("请选择生产设备数量");
        return false;
      } else if (
        this.equipmentCost == "undefined" ||
        this.equipmentCost == ""
      ) {
        this.$message.info("请选择生产设备数量金额");
        return false;
      }
      // else if (this.honors == "undefined" || this.honors == "" || this.honors.length<=0) {
      //   this.$message.info("请选择生产设备数量金额");
      //   return false;
      // }
      let params = {
        enterpriseName: this.enterpriseName,
        creditCode: this.creditCode,
        director: this.director,
        directorPhone: this.directorPhone,
        enterpriseType: this.enterpriseType,
        industryType: this.industryType,
        loanMoney: this.loanMoney,
        salesVolume: this.salesVolume,
        staffCount: this.staffCount,
        equipmentCount: this.equipmentCount,
        equipmentCost: this.equipmentCost,
        honors: this.honors.join(),
        cloudStatus: this.cloudStatus,
        type: this.type,
      };

      applicationAdd(params).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$message.success("提交成功");
          this.resultShow = true;
        } else {
          this.$message.success(res.message);
          this.resultShow = false;
        }

        // this.timer = setInterval(() => {
        //     // this.$router.push('/FinancialPrefecture');
        //     clearInterval(this.timer);
        //   }, 1000);
      });
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.getLabels("jr_enter_type"); //获取企业类型
    this.getLabels("jr_industry_type"); //获取行业分类
    this.getLabels("sales_volume"); //获取销售模式
    this.getLabels("staff_count"); //获取社保人员数量
    this.getLabels("equipment_count"); //获取生产设备数量
    this.getLabels("equipment_cost"); //获取生产设备金额
    this.getLabels("honors"); //获取生产设备金额
  },

  beforeDestroy() {
    // 在组件销毁前清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="less" scoped>
.mainwidth {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}
.digitization-content {
  box-shadow: 0px 2px 10px #929292;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px 40px;

  h2 {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px; /* 根据需要调整间距 */
}
.ant-checkbox-wrapper {
  margin-left: 8px !important;
}
.success-title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}
.success-text {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}
</style>
