import Axios from "../axios";
import BaseUrl from "../base";

// export function getDropDownList(data) {
//   return Axios({
//     method: "get",
//     url: "http://192.168.1.155:9090/crpt/enterprisedemand/dropDownList",
//     params: data,
//   });
// }

// export function applicationAdd(data) {
//   return Axios({
//     method: "post",
//     url: "http://192.168.1.155:9090/crpt/application/add",
//     data: data,
//   });
// }
export function getDropDownList(data) {
  return Axios({
    method: "get",
    url: BaseUrl.cs + "/xcx_bf/dropDownList",
    params: data,
  });
}

export function applicationAdd(data) {
  return Axios({
    method: "post",
    url: BaseUrl.cs + "/xcx_bf/specialApplicationAdd",
    data: data,
  });
}
